define("client-app-omnivise-web/components/login/password-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mt-4 mb-4" data-test-password-expired-error-message>
    <Alert @type="danger" @dismissible={{false}}>
      <:header>
        {{t "screen.login.password-expired.error-message.title"}}
      </:header>
      <:body>
        <p data-test-password-expired-explanation>
          {{t "screen.login.password-expired.error-message.explanation"}}
        </p>
        <p data-test-password-expired-explanation-2>
          {{t "screen.login.password-expired.error-message.explanation-2"}}
        </p>
        <Login::ContactSupport />
        <Button
          @type="secondary"
          data-test-password-expired-back-login-button
          @onClick={{@onBackToLogin}}
        >
          {{t "form.button.back-to-login"}}
        </Button>
      </:body>
    </Alert>
  </div>
  */
  {
    "id": "5HFeQ0cP",
    "block": "{\"symbols\":[\"__arg0\",\"@onBackToLogin\"],\"statements\":[[10,\"div\"],[14,0,\"mt-4 mb-4\"],[12],[2,\"\\n  \"],[8,\"alert\",[],[[\"@type\",\"@dismissible\",\"@namedBlocksInfo\"],[\"danger\",false,[30,[36,3],null,[[\"header\",\"body\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"screen.login.password-expired.error-message.title\"],null]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"screen.login.password-expired.error-message.explanation\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"screen.login.password-expired.error-message.explanation-2\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"login/contact-support\",[],[[],[]],null],[2,\"\\n      \"],[8,\"button\",[],[[\"@type\",\"@onClick\"],[\"secondary\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"form.button.back-to-login\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "client-app-omnivise-web/components/login/password-expired.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});