define("client-app-omnivise-web/components/login/mfa-required", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h5 data-test-mfa-required-title>
    {{t "screen.login.mfa.title"}}
  </h5>
  <p data-test-mfa-required-description>
    {{t "screen.login.mfa.description"}}
  </p>
  <Accordion data-test-mfa-required-accordion as |accordion|>
    {{#each this.availableFactorsWithMetaData as |factorWithMetaData|}}
      <accordion.item
        @value={{factorWithMetaData.id}}
        @title={{factorWithMetaData.name}}
        data-test-second-factor={{factorWithMetaData.name}}
      >
        <div>
          {{component
            factorWithMetaData.component
            factor=factorWithMetaData.factor
            onSecondFactorVerification=@onNextState
          }}
        </div>
      </accordion.item>
    {{/each}}
  </Accordion>
  */
  {
    "id": "BYvhor6l",
    "block": "{\"symbols\":[\"accordion\",\"factorWithMetaData\",\"@onNextState\"],\"statements\":[[10,\"h5\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"screen.login.mfa.title\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"screen.login.mfa.description\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"accordion\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"availableFactorsWithMetaData\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"item\"]],[],[[\"@value\",\"@title\"],[[32,2,[\"id\"]],[32,2,[\"name\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,2,[\"component\"]]],[[\"factor\",\"onSecondFactorVerification\"],[[32,2,[\"factor\"]],[32,3]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "client-app-omnivise-web/components/login/mfa-required.hbs"
  });

  // list of supported factors
  const SUPPORTED_FACTORS = [{
    id: 'google_token:software:totp',
    name: 'Google Authenticator',
    component: 'login/login-second-factor-google-authenticator'
  }, {
    id: 'okta_question',
    name: 'Security question',
    inputLabel: 'Answer',
    component: 'login/login-second-factor-question'
  }];

  class LoginMfaRequired extends _component.default {
    get availableFactorsWithMetaData() {
      const factors = this.args.transaction?.factors;

      if (!Ember.isArray(factors)) {
        return [];
      }

      return SUPPORTED_FACTORS.map(supportedFactor => {
        const factor = factors.find(({
          provider,
          factorType
        }) => {
          const factorId = `${provider}_${factorType}`.toLowerCase();
          return supportedFactor.id === factorId;
        });

        if (!factor) {
          return;
        }

        return Ember.assign({}, supportedFactor, {
          factor
        });
      }).filter(Ember.isPresent);
    }

  }

  _exports.default = LoginMfaRequired;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoginMfaRequired);
});