define("client-app-omnivise-web/components/dropdown", ["exports", "@mosaic-apps/ember-dropdown/components/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dropdown.default;
    }
  });
});