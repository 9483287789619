define("client-app-omnivise-web/routes/application", ["exports", "client-app-omnivise-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    environment,
    useBackend
  } = _environment.default;
  const env = environment === 'production' ? '' : environment === 'qa-server' ? '-qa' : '-dev';
  let Application = (_dec = Ember.inject.service, (_class = (_temp = class Application extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    beforeModel() {
      if (!useBackend) {
        this.store.createRecord('module', {
          id: 'fixture-4',
          name: 'Landing Page',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'The Landing Page',
          'description_en-US': 'The Landing Page for all users to serve as a starting point for the application',
          informationDetail: this.store.createRecord('module-information-detail', {
            contactEmail: 'john.doe@example.com',
            contactFirstName: 'john',
            contactLastName: 'Doe',
            contactPhoneNumber: '+12125551212',
            'benefits_en-US': ['Application Benefiit One', 'Application Benefit Two'],
            'benefits_de-DE': ['Anwendungsvorteil Eins', 'Anwendungsvorteil Zwei'],
            images: ['https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/images/screenshot-asset-monitor.png']
          }),
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-landing-page',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-4',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-4',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'landing-page',
                  downloadUrl: '/landing-page/index-d4ddf3f5400ed1f1a227fc66480afbbc0e60fdb47838785697599af594721ce1.js',
                  authScopes: []
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'asset-monitor',
          name: 'Asset Monitor',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Visualize key performance indicators and time series data critical to your operations. Configurable and easy to use interface to analyze your data',
          'description_en-US': 'Visualize key performance indicators and time series data critical to your operations. Configurable and easy to use interface to analyze your data',
          informationDetail: this.store.createRecord('module-information-detail', {
            contactEmail: 'john.doe@example.com',
            contactFirstName: 'john',
            contactLastName: 'Doe',
            contactPhoneNumber: '+12125551212',
            'benefits_en-US': ['Application Benefiit One', 'Application Benefit Two'],
            'benefits_de-DE': ['Anwendungsvorteil Eins', 'Anwendungsvorteil Zwei'],
            images: ['https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/images/screenshot-asset-monitor.png']
          }),
          views: [this.store.createRecord('external-view', {
            id: 'asset-monitor-page',
            url: `https://asset-monitor.omnivise${env}.siemens-energy.com`
          })]
        });
        this.store.createRecord('module', {
          id: 'm&u',
          name: 'M&U Advisor',
          icon: 'https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/icons/icon-advisor-assist.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'A customer facing M&U advisor web application',
          'description_en-US': 'A customer facing M&U advisor web application',
          informationDetail: this.store.createRecord('module-information-detail', {
            contactEmail: 'john.doe@example.com',
            contactFirstName: 'john',
            contactLastName: 'Doe',
            contactPhoneNumber: '+12125551212',
            'benefits_en-US': ['Application Benefiit One', 'Application Benefit Two'],
            'benefits_de-DE': ['Anwendungsvorteil Eins', 'Anwendungsvorteil Zwei'],
            images: ['https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/images/screenshot-case-bucket.png']
          }),
          views: [this.store.createRecord('external-view', {
            id: 'm-u-page',
            url: `https://mods-and-upgrades${env}.mindsphere.siemens.com/`
          })]
        });
        this.store.createRecord('module', {
          id: 'fuel-advisor',
          name: 'Operational Planning Suite',
          icon: 'https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/icons/icon-advisor-assist.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Application to optimize fuel imbalance costs for GT and CC power plants by providing in depth fuel capacity planning and fuel consumption monitoring along with fuel quality assessments.',
          'description_en-US': 'Application to optimize fuel imbalance costs for GT and CC power plants by providing in depth fuel capacity planning and fuel consumption monitoring along with fuel quality assessments.',
          informationDetail: this.store.createRecord('module-information-detail', {
            contactEmail: 'john.doe@example.com',
            contactFirstName: 'john',
            contactLastName: 'Doe',
            contactPhoneNumber: '+12125551212',
            'benefits_en-US': ['Application Benefiit One', 'Application Benefit Two'],
            'benefits_de-DE': ['Anwendungsvorteil Eins', 'Anwendungsvorteil Zwei'],
            images: ['https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/images/screenshot-case-bucket.png']
          }),
          views: [this.store.createRecord('external-view', {
            id: 'fuel-advisor-page',
            url: `https://operational-planning.omnivise${env}.siemens-energy.com/`
          })]
        });
        this.store.createRecord('module', {
          id: 'performance-advisor',
          name: 'Performance',
          icon: 'https://dene-prd-fra-portal-images.s3.eu-central-1.amazonaws.com/icons/icon-performance-advisor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Continuous Performance Optimization (CPO) Service to maximize the financial yield of your assets.',
          'description_en-US': 'Continuous Performance Optimization (CPO) Service to maximize the financial yield of your assets.',
          views: [this.store.createRecord('external-view', {
            id: 'performance-advisor-page',
            url: `https://performanceadvisor${env}.mindsphere.siemens.com/`
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-smart-controller-monitoring',
          name: 'Smart Controller Monitoring',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Monitoring capabilities for the GT Autotuner Performance Module and Stability/Emission Module.',
          'description_en-US': 'Monitoring capabilities for the GT Autotuner Performance Module and Stability/Emission Module.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-scm',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-smart-controller-monitoring',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-smart-controller-monitoring',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'smart-controller-monitoring',
                  downloadUrl: '/smart-controller-monitoring/index-d1827fa85da639f7ec62925cb8488c1669f16ac7b4da2ea64373c90a54d71aa1.js',
                  authScopes: ['svc-api-gateway.omnivise-dev.siemens-energy.cloud']
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-gen-advisor',
          name: 'Gen Advisor',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Gen Advisor',
          'description_en-US': 'Gen Advisor',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-gen-advisor',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-gen-advisor',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-gen-advisor',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'gen-advisor',
                  downloadUrl: '/gen-advisor/index-a4327b6c55aa31957f327c63783032dfdbcf7e9d116aabecb060765960015178.js',
                  authScopes: ['svc-api-gateway.omnivise-dev.siemens-energy.cloud']
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-fleet-insights',
          name: 'Fleet Insights',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Fleet Insights',
          'description_en-US': 'Fleet Insights',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-fleet-insights',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-fleet-insights',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-fleet-insights',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'fleet-insights',
                  downloadUrl: '/fleet-insights/index-0a5a76b3da1a97b9c7e60b369d585498f0899df6f272229e17980218b2abc865.js',
                  authScopes: ['svc-api-gateway.omnivise-dev.siemens-energy.cloud']
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-engineering-recommendations',
          name: 'Diagnostic Assessments & Recommendations',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture'],
          'badges_en-US': ['fixture'],
          'description_de-DE': 'Diagnostic Assessments & Recommendations',
          'description_en-US': 'Diagnostic Assessments & Recommendationss',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-engineering-recommendations',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-engineering-recommendations',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-engineering-recommendations',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'engineering-recommendations',
                  downloadUrl: '/engineering-recomendations/index-01bf3d1028c37f1846227dccec44127fe0c75add20f8fad62f6632a52f54f449.js',
                  authScopes: ['svc-api-gateway.omnivise-dev.siemens-energy.cloud']
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-1',
          name: 'Hello World',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture', 'testing'],
          'badges_en-US': ['fixture', 'testing'],
          'description_de-DE': 'A simple "Hello World" Omnivise Module meant for testing only.',
          'description_en-US': 'A simple "Hello World" Omnivise Module meant for testing only.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-hello-world',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-1',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-1',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'hello-world',
                  downloadUrl: '/hello-world/index-694ae18f69d9b5117a0e25baeaea7374ddd13b6db227ab1bad5d9b1a10b5d6bf.js',
                  authScopes: []
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-2',
          name: 'Hello World With Assets',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture', 'testing'],
          'badges_en-US': ['fixture', 'testing'],
          'description_de-DE': 'A simple "Hello World" Omnivise Module, which includes some assets. It is meant for testing only.',
          'description_en-US': 'A simple "Hello World" Omnivise Module, which includes some assets. It is meant for testing only.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-hello-world-with-assets',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-2',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-2',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'hello-world-with-assets',
                  downloadUrl: '/hello-world-with-assets/index-54b298bed1d331d87aea0ed1276130ab920e53db9fdc6402a0968c9c2fc43153.js',
                  authScopes: []
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-3',
          name: 'Hello World With Authentication',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture', 'testing'],
          'badges_en-US': ['fixture', 'testing'],
          'description_de-DE': 'A simple "Hello World" Omnivise Module, which uses the authentication information provided by Omnivise framework. It is meant for testing only.',
          'description_en-US': 'A simple "Hello World" Omnivise Module, which uses the authentication information provided by Omnivise framework. It is meant for testing only.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-hello-world-with-auth',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-3',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-3',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'hello-world-with-auth',
                  downloadUrl: '/hello-world-with-auth/index-151e0385ee93d61a661c08693f96ead09a32fee3bc3930995fd52d0078bfbf12.js',
                  authScopes: ['svc-api-gateway.omnivise-dev.siemens-energy.cloud']
                })
              })]
            })]
          })]
        });
        this.store.createRecord('module', {
          id: 'fixture-hello-iframed-ember',
          name: 'Hello Iframed Ember',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture', 'testing'],
          'badges_en-US': ['fixture', 'testing'],
          'description_de-DE': 'An Omnivise Module, which renders an Ember application within an Iframe. It is meant for testing only.',
          'description_en-US': 'An Omnivise Module, which renders an Ember application within an Iframe. It is meant for testing only.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-hello-iframed-ember',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-hello-iframed-ember',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-iframed-ember',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'hello-iframed-ember',
                  downloadUrl: '/hello-iframed-ember/index-c4f0cb1f304b23812a960283cd9d3f39f99f1038093a45e829892125f2a7def7.js',
                  authScopes: []
                })
              })]
            })]
          }), this.store.createRecord('internal-view', {
            id: 'fixture-hello-iframed-ember-test',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-hello-iframed-ember-test',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-iframed-ember-test',
                componentDescriptor: this.store.createRecord('component-descriptor', {
                  id: 'hello-iframed-ember-test',
                  downloadUrl: '/hello-iframed-ember-test/index-c0af885258fd4a8d3eda610b63378b747ebb8a7b7b8aaacb3f7aa5c3126d25c5.js',
                  authScopes: []
                })
              })]
            })]
          })]
        });
        const helloUrlStateComponentDescriptor = this.store.createRecord('component-descriptor', {
          id: 'hello-url-state',
          downloadUrl: '/hello-url-state/index-63d0cfb4bceb81e28de25b2cb4e08bf9ba77eebff82a20c642b0d93581473f95.js',
          authScopes: []
        });
        this.store.createRecord('module', {
          id: 'fixture-hello-url-state',
          name: 'Hello URL State',
          icon: 'https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/app-icons/icon-asset-monitor.svg',
          readOnly: true,
          'badges_de-DE': ['fixture', 'testing'],
          'badges_en-US': ['fixture', 'testing'],
          'description_de-DE': 'An Omnivise Module, which renders a Visualization Component that persists some input in URL. It is meant for testing only.',
          'description_en-US': 'An Omnivise Module, which renders a Visualization Component that persists some input in URL. It is meant for testing only.',
          views: [this.store.createRecord('internal-view', {
            id: 'fixture-hello-url-state',
            configuredWidgets: [this.store.createRecord('configured-widget', {
              id: 'fixture-hello-url-state-a',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-url-state-a',
                componentDescriptor: helloUrlStateComponentDescriptor
              })]
            }), this.store.createRecord('configured-widget', {
              id: 'fixture-hello-url-state-b',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-url-state-b',
                componentDescriptor: helloUrlStateComponentDescriptor
              })]
            }), this.store.createRecord('configured-widget', {
              id: 'fixture-hello-url-state-c',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-url-state-c',
                componentDescriptor: helloUrlStateComponentDescriptor
              })]
            }), this.store.createRecord('configured-widget', {
              id: 'fixture-hello-url-state-d',
              configuredComponents: [this.store.createRecord('configured-component', {
                id: 'fixture-hello-url-state-d',
                componentDescriptor: helloUrlStateComponentDescriptor
              })]
            })]
          })]
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Application;
});