define("client-app-omnivise-web/helpers/validate-date", ["exports", "@mosaic-apps/ember-forms-validation/helpers/validate-date"], function (_exports, _validateDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _validateDate.default;
    }
  });
});