define("client-app-omnivise-web/components/login/login-second-factor-question", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p data-test-question-title>
    {{this.question}}
  </p>
  <Form
    @model={{changeset (hash) (hash answer=(array (validate-presence true)))}}
    @onSubmit={{this.processSecondFactor}}
    data-test-question-form as |form|
  >
    <form.element
      @property="answer"
      placeholder="answer"
      data-test-form-element-for="answer"
    />
    {{#if this.errorMessage}}
      <p class="text-danger" data-test-question-error-message>
        {{this.errorMessage}}
      </p>
    {{/if}}
    <form.submitButton data-test-question-submit as |button|>
      {{if button.isPending (t "form.button.submitting") (t "form.button.submit")
      }}
    </form.submitButton>
  </Form>
  */
  {
    "id": "0BC3wNLS",
    "block": "{\"symbols\":[\"form\",\"button\"],\"statements\":[[10,\"p\"],[12],[2,\"\\n  \"],[1,[32,0,[\"question\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"form\",[],[[\"@model\",\"@onSubmit\"],[[30,[36,3],[[30,[36,2],null,null],[30,[36,2],null,[[\"answer\"],[[30,[36,1],[[30,[36,0],[true],null]],null]]]]],null],[32,0,[\"processSecondFactor\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"element\"]],[[24,\"placeholder\",\"answer\"]],[[\"@property\"],[\"answer\"]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"text-danger\"],[12],[2,\"\\n      \"],[1,[32,0,[\"errorMessage\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,[32,1,[\"submitButton\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,4],[[32,2,[\"isPending\"]],[30,[36,5],[\"form.button.submitting\"],null],[30,[36,5],[\"form.button.submit\"],null]],null]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"validate-presence\",\"array\",\"hash\",\"changeset\",\"if\",\"t\"]}",
    "moduleName": "client-app-omnivise-web/components/login/login-second-factor-question.hbs"
  });

  let LoginSecondFactorQuestion = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class LoginSecondFactorQuestion extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorMessage", _descriptor, this);
    }

    get question() {
      return this.args.factor?.profile.questionText;
    }

    async processSecondFactor({
      answer
    }) {
      try {
        const transaction = await this.args.factor?.verify({
          answer: answer // rememberDevice: true,

        });
        this.args.onSecondFactorVerification(transaction);
      } catch ({
        message
      }) {
        this.errorMessage = message;
        throw new Error(message);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "processSecondFactor", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "processSecondFactor"), _class.prototype)), _class));
  _exports.default = LoginSecondFactorQuestion;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoginSecondFactorQuestion);
});