define("client-app-omnivise-web/utils/get-api-url", ["exports", "client-app-omnivise-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getApiUrl;

  function getApiUrl(url) {
    return `${_environment.default.api.host}/${_environment.default.api.namespace}/${url}`;
  }
});