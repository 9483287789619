define("client-app-omnivise-web/components/modal-body", ["exports", "@mosaic-apps/ember-modals/components/modal-body"], function (_exports, _modalBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modalBody.default;
    }
  });
});