define("client-app-omnivise-web/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AuthRoute extends Ember.Route {
    async beforeModel() {
      return this.transitionTo('auth');
    }

  }

  _exports.default = AuthRoute;
});