define("client-app-omnivise-web/components/redirect-to-auth-if-authenticated", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{did-update this.onAuthenticationChange this.session.isAuthenticated}}
  */
  {
    "id": "M+7kTRtK",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"onAuthenticationChange\"]],[32,0,[\"session\",\"isAuthenticated\"]]],null]]],\"hasEval\":false,\"upvars\":[\"did-update\"]}",
    "moduleName": "client-app-omnivise-web/components/redirect-to-auth-if-authenticated.hbs"
  });

  /**
   * This component observes the `isAuthenticated` state of session service. If
   * it changes to `true`, the component triggers a transition to `auth` route.
   *
   * Changes to `isAuthenticated` state are observed using `{{did-update}}`
   * helper from `ember-render-helpers`.
   *
   * Conceptually this is an observer pattern. But observers aren't part of
   * Ember's programming modal anymore since Octane. So kind of misuing a
   * component to have an observer style pattern.
   *
   * Ember is currently missing a good pattern for this kind of situations.
   * There as been a proposal to add `@use` and `Resources` for this use case.
   * But it's still an experiment. We might want to refactor to that one when
   * it's more stable. Please find more information about it in this RFC
   * https://github.com/emberjs/rfcs/pull/567 and this blog post
   * https://www.pzuraq.com/introducing-use/.
   */
  let RedirectToAuthIfAuthenticated = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class RedirectToAuthIfAuthenticated extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    async onAuthenticationChange() {
      if (this.session.isAuthenticated) {
        await this.session.redirectToDestinationAfterAuthentication();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onAuthenticationChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onAuthenticationChange"), _class.prototype)), _class));
  _exports.default = RedirectToAuthIfAuthenticated;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RedirectToAuthIfAuthenticated);
});