define("client-app-omnivise-web/services/session", ["exports", "@okta/okta-auth-js", "jwt-decode", "ember-local-storage-decorator"], function (_exports, _oktaAuthJs, _jwtDecode, _emberLocalStorageDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  class UnauthenticatedError extends Error {
    constructor(message) {
      super(message);
      this.name = 'UnauthenticatedError';
    }

  }

  class UnknownAuthError extends Error {
    constructor(message) {
      super(message);
      this.name = 'UnknownAuthError';
    }

  } // Time an access token will be refreshed before it expires in seconds


  const REFRESH_THRESHOLD = 300;
  let SessionService = (_dec = Ember.inject.service, _dec2 = (0, _emberLocalStorageDecorator.default)(), _dec3 = (0, _emberLocalStorageDecorator.default)(), _dec4 = Ember._action, (_class = (_temp = class SessionService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "accessTokens", _descriptor2, this);

      _initializerDefineProperty(this, "accessTokenRefreshLock", _descriptor3, this);

      _defineProperty(this, "_authClient", void 0);
    }

    /**
     * @public
     */
    get isAuthenticated() {
      return !!this.accessTokens[this.ownTrustZone];
    }

    get authClient() {
      if (!this._authClient) {
        this._authClient = new _oktaAuthJs.OktaAuth(this.oktaConfig);
      }

      return this._authClient;
    }

    get config() {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }

    get ownTrustZone() {
      return this.config.authConfig.ownAuthTrustZone;
    }

    get environment() {
      return this.config.environment;
    }

    get oktaConfig() {
      const {
        clientId,
        authorizationServerId,
        oktaDomain
      } = this.config.authConfig;
      (false && !(!!clientId) && Ember.assert('clientId config is missing', !!clientId));
      (false && !(!!authorizationServerId) && Ember.assert('authorizationServerId config is missing', !!authorizationServerId));
      (false && !(!!oktaDomain) && Ember.assert('oktaDomain config is missing', !!oktaDomain));
      return {
        clientId,
        issuer: `${oktaDomain}/oauth2/${authorizationServerId}`,
        pkce: false,
        redirectUri: `${window.location.origin}/callback`
      };
    }

    async signInWithCredentials(username, password) {
      try {
        const transaction = await this.authClient.signInWithCredentials({
          username,
          password
        });
        return transaction;
      } catch (error) {
        return {
          status: 'WRONG_CREDENTIALS'
        };
      }
    }

    async handleSuccessfulSignIn(transaction) {
      const accessToken = await this.fetchOwnAccessToken(transaction);
      this.accessTokens = {
        [this.ownTrustZone]: accessToken
      };
      this.scheduleTokenRefreshProcess();
      await this.redirectToDestinationAfterAuthentication();
    }

    async redirectToDestinationAfterAuthentication(queryParams) {
      const {
        redirectUri
      } = queryParams || this.router.currentRoute.queryParams;
      const targetUrl = redirectUri ? decodeURIComponent(redirectUri) : '/auth';
      const isInternalUrl = targetUrl.startsWith('/');

      if (isInternalUrl) {
        const transition = this.router.transitionTo(targetUrl);
        await transition.followRedirects();
      } else {
        window.location.href = targetUrl;
        await new Promise((resolve, reject) => setTimeout(reject, 0));
      }
    }

    async fetchOwnAccessToken({
      sessionToken
    }) {
      return await this.fetchAccessToken([this.ownTrustZone], sessionToken);
    }
    /*
     * Fetches an access token from Okta.
     *
     * Consumer may pass an Okta session token. In that case it will not only
     * fetch the access token but also establish an Okta cookie session in
     * parallel.
     */


    async fetchAccessToken(scopes, sessionToken) {
      const options = {
        responseType: 'token',
        scopes
      };

      if (sessionToken) {
        options.sessionToken = sessionToken;
      }

      let response;

      try {
        response = await this.authClient.token.getWithoutPrompt(options);
      } catch (error) {
        // TODO: Implement granular error indicating the reasons:
        //
        // - If Okta session does not exist, is expired or is invalid (
        //   `MFA_REQUIRED`, `LOCKED_OUT`): Reject with `UnauthenticatedError`.
        // - If valid Okta session exists but trust zone is not configured
        //   correctly: Log to Sentry and reject with `InvalidOktaConfiguration`.
        // - If network is not available: Reject with `NetworkError`.
        // - ...
        // - In all other cases: Reject with `UnknownAuthError`.
        throw new UnauthenticatedError(`Fetching access token for with scopes ${scopes.join(' ')} failed with ${error.message}`);
      }

      const accessToken = response?.tokens?.accessToken?.value;

      if (!accessToken) {
        throw new UnknownAuthError(`Fetching access token for with scopes ${scopes.join(' ')} failed. AuthClient.token.getWithoutPrompt() resolved but response ` + 'did not contain an access token.');
      }

      return accessToken;
    }

    get ownAccessToken() {
      const accessToken = this.accessTokens[this.ownTrustZone]; // TODO: Verify not only that something exists in local storage but also
      //       that it's a valid access token and that it's not expired.

      if (!accessToken) {
        throw new UnauthenticatedError('Tried to use access token for own scope, which does not exist');
      }

      return accessToken;
    } // This method ensures that a valid okta session exists.
    // It does so by trying to fetch an access token for the default trust zone.


    async ensureOktaSessionExists() {
      const accessToken = await this.fetchAccessToken([this.ownTrustZone]); // As that access token is newer than any access token that may exist
      // in local storage, we will persist it.

      this.accessTokens = { ...this.accessTokens,
        [this.ownTrustZone]: accessToken
      };
    } // This method ensures that a valid access token exists for the trust zone
    // given as first argument.
    //
    // It does so by following three steps:
    // 1. It first checks if a valid, not yet expired access token exists in
    //    local storage for this trust zone.
    // 2. If not, it tries to fetch an access token from Okta. If that failes
    //    (e.g. because no Okta session exists), it let's the consumer handle
    //    the error.
    // 3. If it has fetched an access token successfully, that token is persisted
    //    in local storage.


    async ensureAccessTokenFor(trustZone) {
      // TODO: Verify that the access token in local storage is still valid
      if (this.accessTokens[trustZone]) {
        return;
      } // Try to fetch an access token for that trust zone from Okta.


      const accessToken = await this.fetchAccessToken([trustZone]); // Persist access token in local storage.

      this.accessTokens = { ...this.accessTokens,
        [trustZone]: accessToken
      };
    }

    async ensureAccessTokenForDefaultTrustZone() {
      return this.ensureAccessTokenFor(this.ownTrustZone);
    }

    scheduleTokenRefreshProcess() {
      const lockExists = this.accessTokenRefreshLock !== null;
      const thirtySecondsAgo = Date.now() - 30 * 1000;
      const lockIsRecent = this.accessTokenRefreshLock > thirtySecondsAgo;
      const intervalId = setInterval(async () => {
        // check lock
        if (lockExists && lockIsRecent) {
          // some other instance must be handling it
          return;
        } // Either the lock is not present or it is older than 30 secs.
        // so let's check each token to see if we need to refresh
        //set the lock ourselves


        this.accessTokenRefreshLock = Date.now();

        try {
          for (const [authScope, accessToken] of Object.entries(this.accessTokens)) {
            const {
              exp
            } = (0, _jwtDecode.default)(accessToken);

            if (Date.now() / 1000 - exp < REFRESH_THRESHOLD) {
              try {
                await this.fetchAccessToken([authScope]);
              } catch (error) {
                if (error.name === 'UnauthenticatedError') {
                  // TODO: notify user
                  // redirect to login
                  await this.invalidate();
                } // TODO: ignore network not available


                throw error;
              } // update lock


              this.accessTokenRefreshLock = Date.now();
            }
          }
        } finally {
          // remove lock
          this.accessTokenRefreshLock = null;
        }
      }, 30 * 1000);

      Ember._registerDestructor(this, () => clearTimeout(intervalId));
    }

    async invalidate() {
      // logout from okta
      await this.authClient.signOut(); // delete all access tokens from local storage
      // It informs other running instances that a logout event has happend.
      // It must be done _after_ the Okta session is destroyed otherwise we
      // may see crazy timing issues.

      this.accessTokens = {}; // redirect to login

      await this.router.transitionTo('login').promise;
    }
    /**
     * The claims that are encoded in our own access token.
     *
     * @property ownClaims
     * @type {Object}
     * @private
     */


    get ownClaims() {
      return (0, _jwtDecode.default)(this.ownAccessToken);
    }

    get currentUserId() {
      return this.ownClaims.uid;
    }

    get currentUserFirstName() {
      return this.isAuthenticated && this.ownClaims['first_name'];
    }

    get currentUserLastName() {
      return this.isAuthenticated && this.ownClaims['last_name'];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accessTokens", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accessTokenRefreshLock", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "invalidate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "invalidate"), _class.prototype)), _class));
  _exports.default = SessionService;
});