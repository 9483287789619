define("client-app-omnivise-web/components/modules-launcher.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1ol5vg",
    "search": "_search_1ol5vg",
    "empty-search-result-message": "_empty-search-result-message_1ol5vg"
  };
  _exports.default = _default;
});