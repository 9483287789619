define("client-app-omnivise-web/components/login/unknown-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Alert
    data-test-login-unknown-okta-state-alert
    @type="light"
    @dismissible={{false}}
    class="border-danger border-top-0 border-right-0 border-bottom-0 shadow"
  >
    <:header>
      <h4 class="text-danger">
        {{t "screen.login.main.unknown-state.title"}}
      </h4>
    </:header>
  
    <:body>
      <p>
        {{t "screen.login.main.unknown-state.instruction"}}
        <br />
        {{t "screen.login.main.unknown-state.instruction-details"}}
      </p>
      <Login::ContactSupport />
      <div class="mt-2">
        <Button class="mr-2" disabled>
          {{t "form.button.logout"}}
        </Button>
      </div>
    </:body>
  </Alert>
  */
  {
    "id": "0MlsBXQH",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"alert\",[[24,0,\"border-danger border-top-0 border-right-0 border-bottom-0 shadow\"]],[[\"@type\",\"@dismissible\",\"@namedBlocksInfo\"],[\"light\",false,[30,[36,3],null,[[\"header\",\"body\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h4\"],[14,0,\"text-danger\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"screen.login.main.unknown-state.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"screen.login.main.unknown-state.instruction\"],null]],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,0],[\"screen.login.main.unknown-state.instruction-details\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"login/contact-support\",[],[[],[]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n      \"],[8,\"button\",[[24,0,\"mr-2\"],[24,\"disabled\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"form.button.logout\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "client-app-omnivise-web/components/login/unknown-state.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});