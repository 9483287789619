define("client-app-omnivise-web/components/application-footer", ["exports", "@mosaic-apps/application-footer/components/application-footer"], function (_exports, _applicationFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _applicationFooter.default;
    }
  });
});