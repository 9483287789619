define("client-app-omnivise-web/helpers/validate-presence", ["exports", "@mosaic-apps/ember-forms-validation/helpers/validate-presence"], function (_exports, _validatePresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _validatePresence.default;
    }
  });
});