define("client-app-omnivise-web/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginRoute = (_dec = Ember.inject.service, (_class = (_temp = class LoginRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "focused", true);
    }

    async beforeModel(transition) {
      try {
        // Test if a valid Okta session already exists.
        await this.session.ensureOktaSessionExists(); // A valid Okta session exists. Otherwise `ensureOktaSessionExists()`
        // would have thrown. Redirect user either to given redirect URL or
        // to default page for authenticated users.

        this.isComingFromAuthenticatedRoute(transition) ? transition.abort() : this.session.redirectToDestinationAfterAuthentication( // `intent.url` is not public API of transition. But there doesn't
        // seem to be a better way currently to get a URL for a given
        // transition target (e.g. `RouteInfo` available as `transition.to`).
        transition.to.queryParams);
      } catch (error) {// We expect to throw on the above `session.ownAccessToken` call
        // as a way to guarantee that we are not entering this route
        // in an already authenticated state
      }
    }

    isComingFromAuthenticatedRoute(transition) {
      return transition.from?.name.startsWith('auth');
    } // User should be transitioned to /apps if he authenticates in another browser window.
    // Observing localStorage for changes cause that one indicates that access token was
    // changed inanother instance. Storage event is not fired for current instance.


    onLocalStorageChanged({
      key,
      newValue
    }) {
      // ignore changes that aren't affecting access token or removing access token
      if (key !== this.session.ownTrustZone || !newValue || this.focused) {
        return;
      }

      this.transitionTo('auth');
    }

    onWindowFocus() {
      if (!(this.isDestroyed || this.isDestroying)) {
        this.focused = true;
      }
    }

    onWindowBlur() {
      if (!(this.isDestroyed || this.isDestroying)) {
        this.focused = false;
      }
    }

    activate() {
      window.addEventListener('focus', this.onWindowFocus.bind(this));
      window.addEventListener('blur', this.onWindowBlur.bind(this));
      window.addEventListener('storage', this.onLocalStorageChanged.bind(this));
    }

    deactivate() {
      window.removeEventListener('focus', this.onWindowFocus.bind(this));
      window.removeEventListener('blur', this.onWindowBlur.bind(this));
      window.removeEventListener('storage', this.onLocalStorageChanged.bind(this));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginRoute;
});