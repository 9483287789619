define("client-app-omnivise-web/components/login/unauthenticated", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq this.viewState "LOGIN")}}
    <Login::Unauthenticated::LoginForm
      @onNextState={{@onNextState}}
      @onForgotPassword={{this.showForgotPasswordForm}}
      @errorMessage={{@errorMessage}}
    />
  {{else if (eq this.viewState "FORGOT_PASSWORD")}}
    <Login::Unauthenticated::ForgotPasswordForm
      @onNextState={{@onNextState}}
      @onBackToLogin={{this.showLogin}}
    />
  {{/if}}
  */
  {
    "id": "gpUM8iEn",
    "block": "{\"symbols\":[\"@onNextState\",\"@errorMessage\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"viewState\"]],\"LOGIN\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"login/unauthenticated/login-form\",[],[[\"@onNextState\",\"@onForgotPassword\",\"@errorMessage\"],[[32,1],[32,0,[\"showForgotPasswordForm\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"viewState\"]],\"FORGOT_PASSWORD\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"login/unauthenticated/forgot-password-form\",[],[[\"@onNextState\",\"@onBackToLogin\"],[[32,1],[32,0,[\"showLogin\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "client-app-omnivise-web/components/login/unauthenticated.hbs"
  });

  let LoginUnauthenticatedComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class LoginUnauthenticatedComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "viewState", _descriptor, this);
    }

    showLogin() {
      this.viewState = 'LOGIN';
    }

    showForgotPasswordForm() {
      this.viewState = 'FORGOT_PASSWORD';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "viewState", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'LOGIN';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showLogin", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showForgotPasswordForm", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showForgotPasswordForm"), _class.prototype)), _class));
  _exports.default = LoginUnauthenticatedComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LoginUnauthenticatedComponent);
});