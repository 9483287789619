define("client-app-omnivise-web/components/icon", ["exports", "@mosaic-apps/ember-icons/components/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _icon.default;
    }
  });
});