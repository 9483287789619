define("client-app-omnivise-web/templates/auth/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0GcFydA",
    "block": "{\"symbols\":[\"configuredWidget\",\"configuredComponent\",\"view\"],\"statements\":[[10,\"div\"],[14,0,\"bg-white px-0 d-flex flex-column flex-grow-1\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,4],[[32,0,[\"model\",\"internalView\",\"module\",\"views\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-grow-0 flex-shrink-0\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\",\"internalView\",\"module\",\"views\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,3,[\"constructor\",\"modelName\"]],\"internal-view\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@model\"],[\"auth.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"id\"]]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"a\"],[15,6,[32,3,[\"url\"]]],[14,0,\"btn btn-primary\"],[12],[2,\"\\n            \"],[1,[32,3,[\"id\"]]],[2,\"\\n            \"],[8,\"icon\",[[24,0,\"ml-2 text-white-50\"]],[[\"@icon\"],[\"external-link\"]],null],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"model\",\"internalView\",\"configuredWidgets\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1,[\"configuredComponents\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"d-flex flex-grow-1\"],[12],[2,\"\\n        \"],[8,\"visualization-component-renderer\",[],[[\"@configuredComponent\"],[[32,2]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"eq\",\"if\",\"gt\"]}",
    "moduleName": "client-app-omnivise-web/templates/auth/view.hbs"
  });

  _exports.default = _default;
});