define("client-app-omnivise-web/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonStringify = jsonStringify;
  _exports.default = void 0;

  function jsonStringify([value]) {
    if (value === null || value === undefined) {
      return null;
    }

    return JSON.stringify(value);
  }

  var _default = Ember.Helper.helper(jsonStringify);

  _exports.default = _default;
});