define("client-app-omnivise-web/utils/get-normalized-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeLocaleToApi;

  function normalizeLocaleToApi(locale) {
    const [language, territory] = locale.split('-');
    return `${language}-${territory.toUpperCase()}`;
  }
});