define("client-app-omnivise-web/routes/auth/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LANDING_PAGE_INTERNAL_VIEW_ID = void 0;

  /**
   * TODO: Replace this with the known landing page's internal view id once
   * it is available on the back-end.
   */
  const LANDING_PAGE_INTERNAL_VIEW_ID = 'fixture-landing-page';
  _exports.LANDING_PAGE_INTERNAL_VIEW_ID = LANDING_PAGE_INTERNAL_VIEW_ID;

  class IndexRoute extends Ember.Route {
    async beforeModel() {
      this.transitionTo('auth.view', LANDING_PAGE_INTERNAL_VIEW_ID);
    }

  }

  _exports.default = IndexRoute;
});