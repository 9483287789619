define("client-app-omnivise-web/helpers/validate-length", ["exports", "@mosaic-apps/ember-forms-validation/helpers/validate-length"], function (_exports, _validateLength) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _validateLength.default;
    }
  });
});