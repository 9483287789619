define("client-app-omnivise-web/components/login.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "login-card": "_login-card_1yxvuc"
  };
  _exports.default = _default;
});