define("client-app-omnivise-web/templates/terms-of-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+d3Gy8m",
    "block": "{\"symbols\":[],\"statements\":[[8,\"terms-of-use\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "client-app-omnivise-web/templates/terms-of-use.hbs"
  });

  _exports.default = _default;
});