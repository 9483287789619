define("client-app-omnivise-web/components/module.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "card": "_card_1qk1oy",
    "card-header": "_card-header_1qk1oy",
    "card-footer": "_card-footer_1qk1oy",
    "card-info": "_card-info_1qk1oy",
    "module-icon": "_module-icon_1qk1oy",
    "module-screenshot": "_module-screenshot_1qk1oy",
    "module-screenshot-placeholder": "_module-screenshot-placeholder_1qk1oy",
    "module-description-placeholder": "_module-description-placeholder_1qk1oy",
    "module-description-container": "_module-description-container_1qk1oy",
    "fade-in-loader": "_fade-in-loader_1qk1oy",
    "fadeIn": "_fadeIn_1qk1oy"
  };
  _exports.default = _default;
});